<script>
import gql from "graphql-tag";
import VxDialogView from "@/components/vx/VxDialogView";
import BinSelect from "./components/BinSelect";
import CategorySelect from "./components/CategorySelect";
import LocationSelect from "./components/LocationSelect";

import { format, parseISO } from "date-fns";
import { mapMutations } from "vuex";
import { findIndex, minBy } from "lodash";
import { CREATE_BACKSTOCK } from "./graphql";

import {
  backstockRules,
  backstockMethods,
  binsQuery,
  locationsQuery,
} from "./mixins/backstock.js";

export default {
  name: "BackstockNewBinDialog",
  components: {
    BinSelect,
    LocationSelect,
    CategorySelect,
    VxDialogView,
  },
  mixins: [backstockRules, backstockMethods, binsQuery, locationsQuery()],
  props: {
    storeId: {
      type: String,
      default: "",
    },
    binId: {
      type: String,
      default: "",
    },
  },
  data(vm) {
    return {
      newBackStockLoading: false,
      formValid: false,
      invDateMenu: false,
      formattedToday: format(new Date(), "yyyy-MM-dd"),
      newBackstock: {
        bin: {},
        category: {},
        location: {},
        invDate: format(new Date(), "yyyy-MM-dd"),
      },
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    formattedInvDate() {
      // ex: November 12, 2018 (for display purposes)
      let date = this.newBackstock.invDate;
      return format(parseISO(date), "MMMM do, yyyy");
    },
    backstockQueryVariables() {
      return {
        storeId: this.storeId,
      };
    },
  },
  watch: {
    locations(newLocations) {
      // Set default location when we get the locations
      if (newLocations) {
        const defaultLocation = minBy(newLocations, "sortOrder");
        if (defaultLocation && !this.newBackstock.location?.id) {
          this.newBackstock.location = defaultLocation;
        }
      }
    },
    bins: function (newBins) {
      // First check by binId if any change is needed
      // We do this so that we don't change the reference of newBacstock.bin
      // - otherwise newBackstock.bin has a changed value and a validation error is shown imediately after
      // showing dialog (even if user did NOT change any value)
      // Do this change only if binId is specified - case when scanning bin numbers
      if (!!this.binId && this.newBackstock.bin.id !== this.binId) {
        let foundBin = newBins.find((bin) => bin.id === this.binId);
        if (foundBin === undefined) {
          foundBin = {};
        }

        this.newBackstock.bin = foundBin;
      }
    },
    binId: function (newBinId) {
      // First check by binId if any change is needed
      // We do this so that we don't change the reference of newBacstock.bin
      // - otherwise newBackstock.bin has a changed value and a validation error is shown imediately after
      // showing dialog (even if user did NOT change any value)
      // Do this change only if binId is specified - case when scanning bin numbers
      if (!!newBinId && this.newBackstock.bin.id !== newBinId) {
        let foundBin = this.bins.find((bin) => bin.id === newBinId);
        if (foundBin === undefined) {
          foundBin = {};
        }

        this.newBackstock.bin = foundBin;
      }
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    async createBackstock() {
      this.newBackStockLoading = true;
      try {
        const {
          data: {
            createBackstock: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: CREATE_BACKSTOCK,
          variables: {
            input: this.backstockPayload(this.newBackstock),
          },
          update: (
            store,
            {
              data: {
                createBackstock: { backstock },
              },
            }
          ) => {
            // Update the query data to reflect the backstock that was just created
            // 1. Add the backstock to the cache
            // 2. Remove the bin from available bins
            // 3. Category and Location counts are updated automagically
            const variables = { ...this.backstockQueryVariables };

            // -- Add the new backstock to the cache
            // define the backstocks query
            const backstockIdsQuery = gql`
              query ($storeId: ID!) {
                backstocks(storeId: $storeId) {
                  id
                }
              }
            `;
            // read from the cache
            const { backstocks = [] } = store.readQuery({
              query: backstockIdsQuery,
              variables,
            });
            // write back to the cache
            store.writeQuery({
              query: backstockIdsQuery,
              variables,
              data: { backstocks: backstocks.concat([backstock]) },
            });

            // -- Remove the bin
            // define the bin query
            const binsQuery = gql`
              query ($storeId: ID!) {
                bins(storeId: $storeId, available: true) {
                  id
                }
              }
            `;
            // read from the cache
            const { bins = [] } = store.readQuery({
              query: binsQuery,
              variables,
            });
            // find the existing bin
            const binIndex = findIndex(bins, { id: backstock.bin.id });
            if (binIndex > -1) {
              bins.splice(binIndex, 1);
            }
            // write the bins back to the cache
            store.writeQuery({
              query: binsQuery,
              variables,
              data: { bins: [...bins] },
            });
          },
        });
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }
        // if we made it this far, we were successful!
        this.showSnackbar({
          text: this.$t("backstock.app.createBinDialog.successMessage"),
        });
        this.$router.go(-1);
      } catch (error) {
        this.showSnackbar({
          text: this.$t("backstock.app.createBinDialog.errorMessage", {
            error,
          }),
        });
      }
      this.newBackStockLoading = false;
    },
  },
};
</script>
<template>
  <VxDialogView
    :title="$t('backstock.app.createBinDialog.title')"
    :max-width="500"
    :retain-focus="false"
    :v-size="'medium'"
    :h-size="'small'"
  >
    <template #actions>
      <v-btn
        :color="isSmallScreen ? null : 'primary'"
        :text="true"
        :loading="newBackStockLoading"
        :disabled="!formValid"
        @click="createBackstock"
      >
        {{ $t("backstock.app.createBinDialog.okButtonText") }}
      </v-btn>
    </template>
    <template>
      <v-form ref="form" v-model="formValid">
        <BinSelect
          ref="binField"
          v-model="newBackstock.bin"
          :label="$t('backstock.app.createBinDialog.binLabel')"
          :autofocus="!newBackstock.bin || !newBackstock.bin.id"
        />
        <CategorySelect
          ref="catField"
          v-model="newBackstock.category"
          :label="$t('backstock.app.createBinDialog.categoryLabel')"
          :autofocus="!!newBackstock.bin && !!newBackstock.bin.id"
        />
        <v-menu
          v-model="invDateMenu"
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="formattedInvDate"
              :label="$t('backstock.app.createBinDialog.inventoryDateLabel')"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="newBackstock.invDate"
            :max="formattedToday"
            :rules="rules.invDate"
            required
            @change="invDateMenu = false"
          />
        </v-menu>
        <v-text-field
          v-model="newBackstock.notes"
          :label="$t('backstock.app.createBinDialog.notesLabel')"
        />
        <LocationSelect
          v-model="newBackstock.location"
          :label="$t('backstock.app.createBinDialog.locationLabel')"
        />
      </v-form>
    </template>
  </VxDialogView>
</template>
