<template>
  <v-autocomplete
    ref="select"
    :autofocus="autofocus"
    :items="binItems"
    label="Bin"
    item-value="id"
    item-text="name"
    :value="value"
    :rules="localRules"
    :return-object="returnObject"
    :disabled="disabled"
    no-data-text="No empty bins available"
    inputmode="numeric"
    pattern="[0-9]*"
    @input="update"
  />
</template>

<script>
import { backstockSelect } from "../mixins/backstock";
export default {
  name: "BinSelect",
  mixins: [backstockSelect],
  data() {
    return {};
  },
  computed: {
    localRules() {
      return [
        (v) => {
          return (
            (v && this.binItems.map((b) => b.id).includes(v.id)) ||
            "A valid bin is required"
          );
        },
        ...this.rules,
      ];
    },
    binItems() {
      if (this.disabled) return [this.value];
      return this.items || this.bins || [];
    },
  },
};
</script>
